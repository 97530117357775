import {useCallback, useEffect, useState} from 'react';
import './App.css';
import Quiz from './lib/Quiz';
import quiz from './quiz';

function App() {
    const [selectedLessons, setSelectedLessons] = useState([]);
    const [startQuiz, setStartQuiz] = useState(false);
    const setLesson = useCallback((lesson) => {
        let a = JSON.parse(window.localStorage.getItem('lessons'));

        if (a) {
            if (a.find((l) => l == lesson)) {
                a = a.filter((l) => l != lesson);
            } else {
                a = [...a, lesson];
            }
        } else {
            a = [lesson];
        }
        window.localStorage.setItem('lessons', JSON.stringify(a));
        setSelectedLessons(a);
    }, [selectedLessons, setSelectedLessons]);
    useEffect(() => {
        let a = JSON.parse(window.localStorage.getItem('lessons'));
        setSelectedLessons(a ?? []);
    }, []);
    const {quizTitle, quizSynopsis, lessons} = quiz;

    if (!startQuiz) {
        return (
            <>
                <div style={styles.lessonsNumber}>
                    {lessons.filter((l) => {
                        return l.nrOfQuestions > 0 &&
                            l.questions.find(q => q.answers.length > 0);
                    })
                        .map((l) => {
                            return (
                                <div key={l.lessonNumber} style={
                                    selectedLessons.find(
                                        ll => l.lessonNumber == ll) ?
                                        styles.lessonNumberSelected :
                                        styles.lessonNumber}
                                     onClick={setLesson.bind(this,
                                         l.lessonNumber)}>
                                    {l.lessonNumber}
                                </div>
                            );
                        })}
                </div>
                <button onClick={e => {
                    e.preventDefault();
                    setStartQuiz(true);
                }}>
                    inizia il quiz
                </button>
            </>
        );
    }

    const filteredLessons = lessons.filter(
        lesson => selectedLessons.find(l => l == lesson.lessonNumber));
    let questions = filteredLessons.reduce((acc, curr) => {
        return {
            questions: [
                ...acc.questions,
                ...curr.questions,
            ],
            nrOfQuestions: acc.nrOfQuestions + curr.nrOfQuestions,
        };
    }, {questions: [], nrOfQuestions: 0});

    const q = {
        quizTitle,
        quizSynopsis,
        ...questions,
    };

    return (
        <Quiz quiz={q} />
    );
}

export default App;

const styles = {
    lessonsNumber: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    lessonNumber: {
        padding: '10px 6px',
        margin: '5px 6px',
        backgroundColor: '#e2e2e2',
        borderRadius: 4,
        userSelect: 'none',
    },
    lessonNumberSelected: {
        padding: '10px 6px',
        margin: '5px 6px',
        borderRadius: 4,
        backgroundColor: 'red',
        color: 'white',
        userSelect: 'none',
    },
};
